.sliderimg{
  width: 100%;
  height: 50vh;
  object-fit: cover
}


*{
  margin:0;
  padding:0;
 
}

/* .....dark - light mode...... */

.dark-nav{
  background: rgb(43,42,42) !important;
}

.dark-mode-black{
  background: #424242 !important;
  transition: all 0.3s ease-in-out ;
}

.dark-mode-orange{
  color:rgb(218,175,97) !important;
  list-style: italic;

}

.dark-mode-orangeBack{
background: rgb(99,98,98);
}

.dark-mode-white{
  color:#fff;
}

.dark-mode-lightWhite{
  color: #009fff
  ;
  margin-top:10px;
}

.dark-mode-mail{
  color:rgb(30,127,218);
}

.newBar{
  border:1px solid grey !important;
}

.newBorder{
  border:1px solid grey;
}

.dark-mode-blue{
  background: #232424 !important;
  transition: all 0.3 ease-in-out;

}



/* .....end of dark - light mode...... */



li{
  list-style: none;
}

a{
  text-decoration:none ;
}


.App{
  background: #ff6a00 !important;
  transition: all 0.3 ease-in-out;
}

.header{
  width: 100%;
  position: relative;
}

.container{
  max-width:1100px;
  margin:0 auto;
}

.inside-container{
  max-width: 1100px;
  position: absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  margin:0 auto;
}

.header img{
  width:100%;
  height:60vh;
}

.texts{
  position: absolute;
  top:15%;
  text-align: center;
  color:rgb(175,174,174);
  text-shadow: 1px 1px rgb(39,39,39);
}

.texts h1{
  font-size: 22px;
  font-style: italic;
  color: #f953c6
  ;
}

.texts h2{
  font-style: italic;
  color: #6be585;
  text-align: left;
  font-size: 20px;
}

.texts h3{
  font-style: italic;
  font-size: 20px;
  color: 
  #f5af19;
}

 .contactBtn{
  padding:5px 10px !important;
  margin:20px !important;
 color:#fff;
 text-shadow: none;
 border:1px solid #fff;
 width: 90px;
 display: inline-block;
 background: transparent;
 transition: all 0.5s ease-in-out;

}

.contactBtn:hover{

 background: rgb(10,78,146);

}

/* dark mode btn */
 .btnMode{
   position: absolute;
   right:10px;
   top:10px;
   display: flex;
   width:4rem;
   position: fixed;
   top:90px;
 }

 .btnIcons{
   background: rgb(4,67,131);
   border:1px solid transparent;
   width:5rem;
   height: 6px;
   padding:0.5rem;
   display: flex;
   justify-content: space-between;
   margin:0 auto;
   position: relative;
   border-radius: 3rem;
   align-items: center;
   cursor: pointer;
   transition: all 0.3s ease-in-out;
 }

 .newBtnIcons{
   background: #363636;
 }

 .fa-moon{
   color:#e6e4e4;
 }

 .fa-sun{
   color: #f8b500;

 }

 .ball{
   position: absolute;
   left:0;
   width:2rem;
   height:20px;
   background: #e9e4f0;
   border-radius: 3rem;
   transition: all 0.3s ease-in-out;
   margin:0 auto;
 }

 .newBall{
   position: absolute;
   left:50%;
 }
/* end ofdark mode btn */

/* ----------------------------------------------------------------------------------------------------------- */


/* responsive contact */
@media screen and (min-width:350px){
 .header img{
   width:100%;
   height: 85vh;
 }

}


@media screen and (min-width:664px){
  .header img{
    width:100%;
    height: 90vh;
  }

  .texts{
    top:10%;
  }
 
  .texts h1{
    font-size: 35px;
  }

  .texts h2{
    font-size: 25px;

  }

  .contactBtn{
    padding:5px 10px;
    margin:30px;
    font-size: 20px;
    width:120px;

  }


 }


 @media screen and (min-width:964px){

  .header{
    min-height: 90vh;
  }

  .header img{
    width:100%;
    min-height: 100vh;
  }


 }

/* end of header */

/* about */
.about{
  padding:20px 10px;
  line-height: 1.6;
}

.about-logo{
  width:100%;
  margin-bottom: 20px;
  border-radius: 30px;
}

.about-logo img{
  width:100%;
 height: 420px;
 border-radius: 30px;
}

.about-texts span{
  font-weight: bold;
}

.about-texts div{
 border-bottom: 1px solid grey;
 padding:10px;

}

.about-texts ,.about-media{
 border-bottom: 0px solid gray;
}

.about-media{
  display: flex;
  justify-content: space-evenly;
  background: rgb(14, 13, 13);
  border-radius: 20px;
}

.profile h2{
  letter-spacing: 0.2rem;
}

.about-media a{
  flex-grow: 1;
  text-align: center;
  margin:0 20px;
  font-size: 30px;
  color: mediumaquamarine;
  padding:5px 10px;
  border:1px solid transparent;
  transition: all 0.5s ease-in-out;
}

.about-media a:hover{

 
  color:rgb(197,194,194);
background: blue;
color: red;
border:1px solid #fff;
}

.generally{
  margin-top:20px;
}

.generally span{
  text-transform: capitalize;
  font-style: italic;
  font-size:20px;
  color:rgb(28,130,231);
  font-weight: bold;
}

.certificates{
  margin-top:10px;
}

.certificates p{
  padding-left: 20px;
}

/* responsive */
@media screen and (min-width:664px){
 .about-center{
   display: flex;
   justify-content: center;
 }

 .header img{
  height: 100vh;
 }

 .about-logo{
   align-self: center;
   flex:0 0 35%;
   margin:5px;
   height:300px;
   box-shadow: 0 0 5px 1px gray;
   padding:5px;

 }

 .about-logo img{
   height: 300px;
   display: block;
 }

 .about-texts{
   flex-grow: 1;
   margin:0 10px;
 }


}

@media screen and (min-width:886px){
  .about-logo{
    align-self: center;
    flex: 0 0 300px;
    margin: 0 10px;
  }
}


/* skills */

.skills{
  padding:20px 10px;

}

.skills-web{
  margin-bottom:30px;

}

.skills-web h4, .skills-electric h4{
  margin:10px;
}

.skills-center li{
  margin:0;

}

.skills h3{
  font-weight: 500;
  padding-top: 3px;

}

.skills-center li .bar{
  display: block;
  margin:0 auto;
  overflow: hidden;
  border:1px solid black;

}

.bar span{
  background:#fd1d1d;
  display: block;

}

.bar .ninety{
  width:90%;
  animation: ninety 5s infinite;
}

.bar .eighty{
  width: 80%;
}

.bar .seventy{
  width: 70%;
}

.bar .sixty{
  width: 60%;
}

.bar .fifty{
  width: 50%;
}

.bar .thirty{
  width: 30%;
}

.bar p{
 color:#fff;
 text-align: right;
 display: block;
 padding-right: 10px;
}

@keyframes ninety{
  0%{
   width:0%;
  }

  100%{
    width:90%;
   }
}

@keyframes eighty{
  0%{
   width:0%;
  }

  100%{
    width:80%;
   }
}

@keyframes seventy{
  0%{
   width:0%;
  }

  100%{
    width:70%;
   }
}

@keyframes sixty{
  0%{
   width:0%;
  }

  100%{
    width:60%;
   }
}

@keyframes fifty{
  0%{
   width:0%;
  }

  100%{
    width:50%;
   }
}

@keyframes thirty{
  0%{
   width:0%;
  }

  100%{
    width:30%;
   }
}

.skills-web h4,
.skills-electric h4{
  text-align: center;
  font-weight: 400;
  letter-spacing: 0.3rem;
}

@media screen and (min-width:664px){
 .skills-center{
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
 }

 .skills-web,
 .skills-electric{
   flex:0 0 calc(50% - 30px);
 }

 .skills-web{
   margin-bottom: 0;
 }

 .skills-web h4,
 .skills-electric h4{
  margin:10px;
 }



}

/* projects */
.projects{
  padding:20px 10px;
}

.single-project{
  width:100%;
  height: 100%;
  position: relative;
  margin:0px;
  margin-top:20px;
  background: rgba(0,0,0,0.9);
  overflow: hidden;
}

.single-project a{
  width: 100%;
  display: block;
}

.single-project img{
  width: 100%;
  display: block;
  opacity: 0.8;
  transition: all 0.5s ease-in-out;
}

.single-project:hover img{
  opacity:0.5;
}

.single-project p{
  font-size:20px;
  color: #eaeaea;
  position: absolute;
  top:-50px;
  text-align: center;
  left:0;
  right:0;
  background: rgba(0,0,0,0.4);
  margin:0;
  padding:10px;
  display: block;
  transition: all 0.5s ease-in-out;
}

.single-project:hover p{
  top:0;
}

.items .projectBtn{
  font-size: 18px;
  padding:5px 10px;
  width:180px;
  color:#fff;
  cursor: pointer;
  border:1px solid #fff;
  transition: all 0.5s ease-in-out;
  background: transparent;
}

.items .projectBtn a{
  color:#fff;
  transition: all 0.5s ease-in-out;
}

.items .projectBtn:hover a{
  color:#323232;
  
}

.items{
  position: absolute;
  left:0;
  right:0;
  flex-direction: column;
  background: rgba(0,0,0,0.4);
  transition: all 0.5s ease-in-out;
  bottom: 0;
  padding:10px;
  text-align: center;
  
}

.items .projectBtn:hover{
  color:#323232;
  background: rgb(214,216,218);
  border:1px solid #fff;
  opacity: 0.7;

}

.single-project:hover .items{
  bottom: 0;
}

@media screen and (min-width:664px){
 .projects-center{
   display: flex;
   flex-wrap: wrap;
 justify-content: center;
 }

 .single-project{
   flex:0 0 calc(50% - 20px);
   margin:10px auto;
   margin-bottom: 10px;
 }


}


@media screen and (min-width:864px){
  .projects-center{
    display: flex;
    flex-wrap: wrap;
  justify-content: space-between;
  margin:0;
  }
 
  .single-project{
    flex:0 0 calc(33.3% - 20px);
   
  }

  .items{
    bottom: -60px;
  }
 
  
 }


/* courses */
.courses{
  padding:20px 10px;
}

.single-course{
  width:100;
  height: 100%;
  margin-bottom: 30px;
  background: #fdfc47;
  box-shadow: 0 0 4px 1px gray;

}

.courses-center:last-child{
  margin-bottom: 0;
}

.single-course iframe{
  width:100%;
  height: 40vh;
  display: block;
}

.single-course p{
  text-align:center;
  font-size:20px;
  padding:5px;
  color:rgb(97,97,97);
  letter-spacing: 0.3rem;

}


@media screen and (min-width:360px){
 .single-course iframe{
  width:100%;
  height:35vh;
 }


}

@media screen and (min-width:450px){
  .single-course iframe{
   width:100%;
   height:45vh;
  }
 
  
 }

 @media screen and (min-width:664px){
 .courses-center{
   display: flex;
   flex-wrap: wrap;

 }

 .single-course{
   flex:0 0 calc(50% - 20px);
   margin:10px auto;
 }

 .single-course iframe{
   height: 35vh;
 }
 
  
 }

 
 @media screen and (min-width:864px){
 
  .single-course{
    flex:0 0 calc(33.3% - 20px);
    margin:10px auto;
  }
 
  .single-course iframe{
    height: 35vh;
    
  }
  
   
  }


  /* contact */
  .contact{
    background: #0099f7
    ;
    padding:20px 10px;

  }

  .contact-center h3{
    color: #6f0000;
    letter-spacing: 0.3rem;
    text-align: center;
  }

  .contact-links li a{
    display: inline-block;
    font-size: 18px;
    margin:5px;
    padding:2px;
    color:#181818;
    text-transform: capitalize;

  }

  .contact-links li a:hover{
    color: #ff4b1f;
    text-decoration: underline;

  }

  .contact-links .links-items{
    display: block;
    justify-content: center;
    margin:0 auto;
    text-align: center;
  }



  .contact-links{
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

  }

  .contact-media li a{
    display: inline-block;
    font-size: 18px;
    margin:5px;
    padding:5px;
    color:#093637;
    text-transform: capitalize;
  }

  .contact-media li a:hover{
  color: #ff4b1f;
  }

  .contact-media .media-items{
    display: flex;
    justify-content: center;

  }

  /* responsive */

  @media screen and (min-width:450px){

 .contact-links li a{
   display: inline-block;
   font-size: 18px;
   color: #093637;
   text-transform: capitalize;
   text-align: left;
 }

 .contact-links .links-items{
   display: flex;
   justify-content: center;
   margin:0 auto;
   
 }


  }

  @media screen and (min-width:664px){

   .media-items .fab{
     font-size: 25px;
   }
   
     }


     @media screen and (min-width:964px){

     .contact-center{
       display: flex;
       justify-content: center;
       width:100%;
     }

     .contact-links, .contact-media{
       margin:0 30px;
     }
      
        }

        .created{
          background: #f00000;
          box-shadow: 0 0 4px 1px lightgray;
        }

        .created p{
          text-align: center;
          padding:10px;
          color: #ffffff;
          font-style:italic;
          font-size: 18px;
        }



